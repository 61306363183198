"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var config = {
    api: {
        foodOpsApiBaseUrl: 'https://3dqjptzz70.execute-api.us-east-1.amazonaws.com/stage/foodops/graphql',
        commonApi: 'https://3dqjptzz70.execute-api.us-east-1.amazonaws.com/stage/common',
        apiKey: 'xablauapikeyxablau123-stage',
        webSocketBaseUrl: 'wss://a4w00da8c0.execute-api.us-east-1.amazonaws.com/stage'
    },
    cloudfront: 'd17hqorr29jksg.cloudfront.net',
    cognito: {
        foodOpsUserPoolId: 'us-east-1_AOgRqv738',
        foodOpsUserPoolClientId: '3df8maeone4s2je3l5mpj9rr4g'
    },
    pinpoint: 'dab104cf8b74420787417f6e71653be7',
    region: 'us-east-1',
    secretsManager: {
        mapsApiKey: 'AIzaSyDLP8REBjQ8Goq1vIyX522we4aMnByRU7k'
    }
};
exports.default = config;
